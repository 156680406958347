import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import GroupsIcon from '@mui/icons-material/Groups';
import InfoIcon from '@mui/icons-material/Info';
import {
  Layout,
  ProtectedRoute,
  useIdobaAuth,
  smartlookClient,
} from '@optika-solutions/shared-react';
import { Route, Routes } from 'react-router-dom';
import Account from 'views/Account';
import Login from 'views/login/Login';
import Module from 'views/Module';
import NotFound from 'views/NotFound';
import Version from 'views/Version';
import { CONFIG } from 'utils/config';

const Routing = () => {
  const { authService } = useIdobaAuth();
  const { identifySmartlook, initialiseSmartlook } = smartlookClient;
  const smartlookToken = CONFIG?.smartlook_token || '';
  if (smartlookToken) {
    initialiseSmartlook(smartlookToken);
  }

  const routeMenu = [{ link: '/modules', name: 'Module', icon: <CalendarViewMonthIcon /> }];

  // Ideally features shouldn't be merged to develop branch or made visible until they're
  // complete and able to put in front of customers (subject to review and feedback).
  // Hiding this one for now since it's already in the develop branch:
  if (process.env.NODE_ENV === 'development') {
    routeMenu.push({ link: '/accounts', name: 'Accounts', icon: <GroupsIcon /> });
  }

  // Only link to the version page in development
  if (process.env.NODE_ENV === 'development') {
    routeMenu.push({ link: '/version', name: 'Version', icon: <InfoIcon /> });
  }

  const layoutProps = {
    title: 'DiiMOS',
    menuItems: routeMenu,
    onLogout: authService.logout,
  };

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Login />} />
      <Route path="/not_found" element={<NotFound />}></Route>

      <Route path="/version" element={<ProtectedRoute />}>
        <Route path="/version" element={<Layout {...layoutProps} children={<Version />} />} />
      </Route>

      <Route path="/modules" element={<ProtectedRoute />}>
        <Route
          path="/modules"
          element={
            <Layout
              {...layoutProps}
              children={<Module smartlookIdentityFn={identifySmartlook} />}
            />
          }
        />
      </Route>

      <Route path="/accounts" element={<ProtectedRoute />}>
        <Route path="/accounts" element={<Layout {...layoutProps} children={<Account />} />} />
      </Route>
    </Routes>
  );
};

export default Routing;
