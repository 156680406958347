import React, { useEffect, useState } from 'react';
import { Alert, Box, Container, Fade } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { useIdobaAuth } from '@optika-solutions/shared-react';
import LoginFooter from 'components/login/LoginFooter/LoginFooter';
import LoginForm from 'components/login/LoginForm';
import ResetPasswordForm from 'components/login/ResetPasswordForm';
import SetNewPasswordForm from 'components/login/SetNewPasswordForm';
import IdobaLogo from '../../components/icons/IdobaLogin';
import { checkPassword } from '../../utils/validate';
import { alertObjectType, loginErrorTypes, loginFormsTypes } from 'utils/types';
import axios from 'axios';
import { CONFIG } from 'utils/config';

const Cookies = require('js-cookie');

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasUsernameError, setHasUsernameError] = useState(false);
  const [hasConfirmationCodeError, setHasConfirmationCodeError] = useState(false);
  const [hasPasswordError, setHasPasswordError] = useState(false);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [displayView, setDisplayView] = useState<loginFormsTypes>(loginFormsTypes.login);
  const returnUrl = params.get('return_url');
  const [rePassword, setRePassword] = useState('');
  const [globalMessage, setGlobalMessage] = useState('');
  const { authService } = useIdobaAuth();

  const passwordValidateMessage =
    'Password must be at least 12 characters long and use at least one uppercase letter, number and special character.';
  const successfullyChangedPasswordText = 'Your DiiMOS password has been successfully changed.';
  const emailSentForValidationText =
    'An email with further instructions has been sent to the address associated with your username';
  const logoutText = 'You have been successfully logged out of DiiMOS';
  const invalidCodeProvided = 'Invalid confirmation code, please try again.';
  // @TODO - temp place for getting alerts going there are alot of states up above, might be good
  // to take a look at what can be simplified.

  const [alertObject, setAlertObject] = useState<alertObjectType | undefined>(undefined);
  const [showAlert, setShowAlert] = useState(false);

  // Used for testing view states
  useEffect(() => {
    const view = params.get('view') as loginFormsTypes | null;

    if (
      view &&
      [
        loginFormsTypes.resetPassword,
        loginFormsTypes.forgetPasswordCode,
        loginFormsTypes.setNewPassword,
      ].includes(view)
    ) {
      setDisplayView(view);
    }
  }, [setDisplayView, params]);

  useEffect(() => {
    const checkAuth = async () => {
      if (await authService.isAuthenticated) {
        if (returnUrl) {
          window.location.replace(returnUrl);
          return;
        } else {
          navigate('/modules');
        }
      }
    };

    checkAuth();
  }, [setGlobalMessage, navigate, returnUrl, authService]);

  useEffect(() => {
    if (alertObject) {
      setShowAlert(true);
    }
    if (alertObject?.timeout) {
      const alertTimer = setTimeout(() => {
        setShowAlert(false);
      }, alertObject.timeout);

      return () => clearTimeout(alertTimer);
    }
  }, [alertObject]);

  const clearLoginScreen = (view: loginFormsTypes) => {
    if (view !== loginFormsTypes.resetPassword && view !== loginFormsTypes.setNewPassword) {
      setUsername('');
    }
    if (![loginFormsTypes.setNewPassword, loginFormsTypes.resetPassword].includes(view)) {
      setPassword('');
      setRePassword('');
      setConfirmationCode('');
    }
    setErrors('');
    setHasUsernameError(false);
    setHasPasswordError(false);
    setHasConfirmationCodeError(false);
    setLoading(false);
  };

  useEffect(() => {
    clearLoginScreen(displayView);
  }, [displayView]);

  useEffect(() => {
    //if password error is set, and the user has changed the password at all, clear the error until triggered again
    if (hasPasswordError) {
      setHasPasswordError(false);
    }
    if (errors) {
      setErrors('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, hasPasswordError]);

  const forgetPassword = () => {
    setDisplayView(loginFormsTypes.forgetPasswordCode);
  };

  const resetPassword = () => {
    setDisplayView(loginFormsTypes.setNewPassword);
  };

  const backNavigation = () => {
    setDisplayView(loginFormsTypes.login);
  };

  const resetErrors = (errorType: loginErrorTypes) => {
    setErrors('');
    switch (errorType) {
      case loginErrorTypes.username:
        setHasUsernameError(false);
        break;
      case loginErrorTypes['confirmation-code']:
        setHasConfirmationCodeError(false);
        break;
      case loginErrorTypes.password:
        setHasPasswordError(false);
        break;
      default:
        break;
    }
  };

  const login = () => {
    setLoading(true);
    setErrors('');
    setHasUsernameError(false);
    setHasPasswordError(false);

    authService
      .login(username, password)
      .then((result) => {
        if (result.data) {
          if (returnUrl) {
            window.location.replace(returnUrl);
          } else {
            navigate('/modules');
          }
        }
        setLoading(false);
        if (result.status && [400, 401].includes(result.status)) {
          setErrors('Email address and password combination is incorrect. Please try again.');
        } else {
          setErrors(result.message);
        }
      })
      .catch((error: Error) => {
        setLoading(false);
        setErrors(error.message);
      });
  };

  // @TODO - sendCode not entirly helpful might be worth renaming to "setPasswordReset"
  const sendCode = () => {
    // Alert user that if their email is stored, they should be getting confirmation code.
    setAlertObject({ type: 'success', message: emailSentForValidationText });
    setDisplayView(loginFormsTypes.resetPassword);

    if (!username) {
      setErrors('Email is a required field');
      setHasUsernameError(true);
      return;
    }
    setLoading(true);
    resetErrors(loginErrorTypes.username);

    authService
      .requestPasswordReset(username)
      .then((result) => {
        if (result) {
          if (!result.success) {
            setHasUsernameError(true);
            setLoading(false);

            return;
          }
        }
        setLoading(false);
      })
      .catch((error: Error) => {
        setLoading(false);
        console.log('Login has returned undefined error of: ', error);
      });
  };

  const backToValidationCode = () => {
    setDisplayView(loginFormsTypes.resetPassword);
  };

  const setNewPassword = () => {
    if (!username || !password || !confirmationCode || !rePassword) {
      setAlertObject({ type: 'error', message: 'Required fields are missing', timeout: 5000 });
      setHasUsernameError(true);
      setHasConfirmationCodeError(true);
      setHasPasswordError(true);
      return;
    }

    if (!checkPassword(password)) {
      setErrors(passwordValidateMessage);
      setHasPasswordError(true);
      return;
    }

    setLoading(true);
    setErrors('');
    setHasUsernameError(false);
    setHasConfirmationCodeError(false);
    setHasPasswordError(false);

    authService
      .resetPassword(username, confirmationCode, password)
      .then((result) => {
        setLoading(false);

        if (!result.success) {
          //@TODO make into helper function or look into how to edit cognito response messages at api level
          const hyjackingCognitoErrorResponse = result.body.includes('Invalid code')
            ? invalidCodeProvided
            : result.body;
          setHasConfirmationCodeError(true);
          setAlertObject({
            type: 'error',
            message: hyjackingCognitoErrorResponse,
            timeout: 5000,
          });
          return;
        }
        setDisplayView(loginFormsTypes.login);
        setAlertObject({
          type: 'success',
          message: successfullyChangedPasswordText,
          timeout: 3000,
        });
      })
      .catch((error_result: Error) => {
        // To note: the failed to reset rejection is handled as a result.success === false and wont reach here.
        setLoading(false);
        console.log('Reset Password Unexpected Failure ', error_result);
      });
  };

  const loginWithSSO = async () => {
    if (CONFIG.auth.sso === undefined) {
      throw new Error('SSO is not configured');
    }
    const urlString = CONFIG.auth.sso.cognitoUrl;
    const params: { [index: string]: string } = {
      response_type: 'code',
      client_id: CONFIG.auth.sso.clientId,
      redirect_uri: CONFIG.auth.sso.redirectUri,
      scope: 'aws.cognito.signin.user.admin email openid phone profile',
      identity_provider: CONFIG.auth.sso.identityProvider,
    };

    const data = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join('&');

    const response = urlString + '?' + data;

    window.location.href = response;
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (code) {
      const checkAuthCode = async () => {
        const code = params.get('code');

        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${CONFIG.auth.api}/sso?code=${code}`,
        };

        try {
          const response = await axios.request(config);
          const idToken = response.data.idToken;

          Cookies.set('CognitoIdToken', idToken, {
            expires: 1,
            secure: true,
            sameSite: 'strict',
            domain: CONFIG.cookieDomain,
          });

          // Once we have the code, we can navigate to the modules page
          window.location.href = '/modules';
        } catch (error) {
          console.error(error);
        }
      };
      checkAuthCode();
    }
  }, [navigate]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      {globalMessage === 'logout' && (
        <Alert sx={{ mt: 5, width: '441px', left: '532px' }} severity="info">
          {logoutText}
        </Alert>
      )}

      <Container sx={{ mt: globalMessage ? 13 : 22 }} maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 126,
            borderRadius: '4px',
            mb: '32px',
          }}
        >
          <IdobaLogo />
        </Box>

        {displayView === loginFormsTypes.login && (
          <>
            <LoginForm
              username={username}
              password={password}
              setUsername={setUsername}
              setPassword={setPassword}
              isLoading={loading}
              hasUsernameError={hasUsernameError}
              hasPasswordError={hasPasswordError}
              errors={errors}
              onLogin={login}
              forgetPasswordHandler={forgetPassword}
              setAlert={setAlertObject}
            />
            {CONFIG.auth.sso && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: 126,
                  borderRadius: '4px',
                  mb: '32px',
                }}
              >
                <LoadingButton
                  sx={{
                    bgcolor: 'darkpurple',
                  }}
                  type="submit"
                  variant="contained"
                  fullWidth={true}
                  size="large"
                  loading={false}
                  onClick={async () => {
                    console.info({ message: 'SSO Login Initiated' });
                    await loginWithSSO();
                  }}
                >
                  SSO Login
                </LoadingButton>
              </Box>
            )}
          </>
        )}

        {(displayView === loginFormsTypes.forgetPasswordCode ||
          displayView === loginFormsTypes.resetPassword) && (
          <ResetPasswordForm
            displayView={displayView}
            username={username}
            confirmationCode={confirmationCode}
            setUsername={setUsername}
            setConfirmationCode={setConfirmationCode}
            resetPassword={resetPassword}
            sendCode={sendCode}
            backNavigation={backNavigation}
            hasUsernameError={hasUsernameError}
            errors={errors}
            hasReadError={resetErrors}
            hasConfirmationCodeError={hasConfirmationCodeError}
            isLoading={loading}
          />
        )}

        {displayView === loginFormsTypes.setNewPassword && (
          <SetNewPasswordForm
            password={password}
            rePassword={rePassword}
            setPassword={setPassword}
            setRePassword={setRePassword}
            email={username}
            isLoading={loading}
            onSetNewPassword={setNewPassword}
            backToValidationCode={backToValidationCode}
            hasConfirmationCodeError={hasConfirmationCodeError}
          />
        )}

        <LoginFooter />
      </Container>
      <Fade
        in={showAlert}
        easing={{ exit: 'ease-out' }}
        timeout={{ enter: 0, exit: 1000 }}
        onExited={() => setAlertObject(undefined)}
      >
        <Alert
          sx={{
            position: 'absolute',
            top: '56px',
            zIndex: 20,
          }}
          iconMapping={{
            success: <CheckCircleOutline fontSize="inherit" />,
          }}
          onClose={!alertObject?.timeout ? () => setShowAlert(false) : undefined}
          severity={alertObject?.type}
        >
          {alertObject?.message}
        </Alert>
      </Fade>
    </Box>
  );
};

export default Login;
