import { styled } from '@mui/system';
import { Link, LinkBaseProps } from '@mui/material';

const LinkComponent = (props: LinkBaseProps) => (
  <Link component="button" variant="overline" color="inherit" {...props}>
    {props.children}
  </Link>
);

const LinkStyled = styled(LinkComponent)(({ theme }) => ({
  mt: 3,
  fontSize: 14,
  letterSpacing: 0.15,
  color: theme.palette.primary.main,
  textTransform: 'none',
  lineHeight: 'initial',
}));

export default LinkStyled;
