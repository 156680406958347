import validate from 'validate.js';
import { z } from 'zod';

export const checkPassword = (value: string, email?: string) => {
  const isNonWhiteSpace = /^\S*$/;
  const error = [];
  if (!isNonWhiteSpace.test(value)) {
    error.push('Password must not contain Whitespaces.');
  }

  const isContainsUppercase = /^(?=.*[A-Z]).*$/;
  if (!isContainsUppercase.test(value)) {
    error.push('Password must have at least one Uppercase Character.');
  }

  const isContainsLowercase = /^(?=.*[a-z]).*$/;
  if (!isContainsLowercase.test(value)) {
    error.push('Password must have at least one Lowercase Character.');
  }

  const isContainsNumber = /^(?=.*[0-9]).*$/;
  if (!isContainsNumber.test(value)) {
    error.push('Password must contain at least one Digit.');
  }

  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;''<>,.?/_₹]).*$/;
  if (!isContainsSymbol.test(value)) {
    error.push('Password must contain at least one Special Symbol.');
  }

  const isValidLength = /^.{12,}$/;
  if (!isValidLength.test(value)) {
    error.push('Password must have at least 12 Characters.');
  }

  const emailPrefix = email ? email.split('@')[0] : '';
  if (value.toLocaleLowerCase() === emailPrefix.toLocaleLowerCase()) {
    error.push('Password must not match email');
  }

  return error.length ? false : true;
};

//@TODO not sure why the above function doesnt pass the actual errors through,
// but its in use at the moment, so i'll create a new one for now and get rid of the
// one above when i have some time.
export const validatePassword = (newPassword: string, email?: string) => {
  let error = '';

  const isValidLength = /^.{12,}$/;
  if (!isValidLength.test(newPassword)) {
    error = 'Password must have at least 12 Characters.';
  }

  const isContainsUppercase = /^(?=.*[A-Z]).*$/;
  if (!isContainsUppercase.test(newPassword)) {
    error = 'Password must have at least one Uppercase Character.';
  }

  const isContainsLowercase = /^(?=.*[a-z]).*$/;
  if (!isContainsLowercase.test(newPassword)) {
    error = 'Password must have at least one Lowercase Character.';
  }

  const isContainsNumber = /^(?=.*[0-9]).*$/;
  if (!isContainsNumber.test(newPassword)) {
    error = 'Password must contain at least one Digit.';
  }

  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;''<>,.?/_₹]).*$/;
  if (!isContainsSymbol.test(newPassword)) {
    error = 'Password must contain at least one Special Symbol.';
  }

  if (newPassword.trim() !== newPassword) {
    error = 'Password must not have a leading or trailing whitespace.';
  }

  const emailPrefix = email ? email.split('@')[0] : '';
  if (newPassword.toLocaleLowerCase() === emailPrefix.toLocaleLowerCase()) {
    error = 'Password must not match email';
  }

  return error;
};

export const checkEmail = (value: string) => {
  const emailRegEx = /^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i;
  return emailRegEx.test(value);
};

export const checkEmailV2 = (value: string) => {
  const constraints = {
    from: {
      email: true,
    },
  };
  const isValid: boolean = validate({ from: value }, constraints);
  return !isValid;
};

//@TODO: going to convert this when the rework of the forms starts, to
//integrate zod into the form validation and will not need helper functions like this.
//but for now, this quick change allows no longer having to maintain our own email validation code.
//and change none of the logic we have current.

export const checkEmailZod = (value: string) => {
  const emailSchema = z.string().email();
  try {
    emailSchema.parse(value);
    return true;
  } catch (error) {
    return false;
  }
};
